<template>
  <v-popover
    :placement="placement"
    :auto-hide="autoHide"
    :boundaries-element="$root.body"
    :popover-class="`menu ${popoverClass}`"
    @click.native.stop
  >
    <Button
      v-if="!hasTriggerSlot"
      v-tooltip.top-center="{content: `More`, classes: 'small'}"
      icon="ellipsis"
      class="circle transparent ellipsis"
    />
    <slot name="trigger" />
    <template
      slot="popover"
    >
      <slot />
    </template>
  </v-popover>
</template>
<script lang="ts">
import Vue from 'vue'

import Button from '@/components/atoms/Button.vue'
export default Vue.extend({
  components: {
    Button
  },
  props: {
    placement: {
      type: String,
      default: 'top',
      // validator: (value: string) => ['top', 'right', 'bottom', 'left','top-start'].includes(value)
    },
    autoHide: {
      type: Boolean,
      default: true
    },
	popoverClass: {
		type: String,
		default: ''
	}
  },
  computed: {
    hasTriggerSlot () {
      return !!this.$slots.trigger
    }
  }
})
</script>
<style lang="less" scoped>
.popover-inner{
  font-size: 0.85rem;
}

</style>