<template>
  <div class="user-container">
    <div class="avatar-container">
      <img
        v-if="avatar"
        :src="avatar"
      >
      <span v-if="!avatar">
        <IconSvg name="selfie" />
      </span>
    </div>
	
	<div :class="{dropdown}">
		<div v-if="$store.state.user.me.role === 'admin'">
			<span class="tag size-s uppercase bold green color-black">
			<IconSvg name="star" />
			A11 Admin User
			</span>
		</div>
		<strong>
			{{ $store.state.user.me.local.firstName }} {{ $store.state.user.me.local.lastName }}
		</strong><br>
		{{ $store.state.user.me.local.email }}<br>
		<div v-if="$store.getters['account/hasAny']">
			<HorizontalRule />
			<router-link
			:to="{name:'user'}"
			>
			User Settings
			</router-link>
		</div>
		<HorizontalRule />
		<a @click="$store.dispatch('user/logout')">Sign Out</a>
	</div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import store from '@/store'
import IconSvg from "@/components/atoms/IconSvg.vue";
import HorizontalRule from "@/components/atoms/HorizontalRule.vue";


export default Vue.extend({
  components: {IconSvg, HorizontalRule},
  props: {
	dropdown: {
		type: Boolean,
		default: false
	}
  },
  computed: {
    avatar () {
      return store.state.user.me.local.avatar
    },
    initials () {
      return store.getters['user/initials']
    },
    fullName () {
      return store.getters['user/fullName']
    }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/variables';

  .user-container{
    display: flex;
    position: relative;
    flex-direction: column;
    color: @grayMedium;
  }

  .avatar-container{
    display: inline-block;
    float: left;
    background: @grayLight;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    font-weight: 600;
    overflow: hidden;
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .user-name{
    color: @grayMedium;
    margin-left: 0.5em;
  }

  .horizontal-rule{
	margin: 0.5em 0;
  }
</style>
