
// INCLUDE THESE PAGES IN THE MAIN BUNDLE
// LAZY-LOAD OTHER PAGES USING import()
// ============================================
import nprogress from 'nprogress'
import Vue from 'vue'
import VueRouter from 'vue-router'

import PageLogin from '@/pages/PageLogin.vue'
import PageSplash from '@/pages/PageSplash.vue'
import store from '@/store'

nprogress.configure({
  showSpinner: false,
  minimum: 0.25,
  speed: 500,
  ease: 'ease',
});

Vue.use(VueRouter)

// Create a map to store scroll positions
const scrollPositions = new Map<string, { x: number; y: number }>()

const router = new VueRouter({
	scrollBehavior(to, from, storedPosition) {
		const scrollableDiv = document.querySelector('.app-content')
		
		// Store current scroll position before leaving
		if (from.path) {
			scrollPositions.set(from.path, {
				y: scrollableDiv.scrollTop,
				x: scrollableDiv.scrollLeft
			})
		}
	
		// On forward navigation (no stored position), scroll to top
		if (storedPosition) {
			// On back navigation, restore position
			const storedPosition = scrollPositions.get(to.path)
			if (storedPosition) {
				setTimeout(() => {
					scrollableDiv.scrollTo({
						top: storedPosition.y,
						left: storedPosition.x,
						behavior: 'instant'
					})
				})
			}
		}else{
			setTimeout(() => {
			  scrollableDiv.scrollTo({
				top: 0,
				behavior: 'instant'
			  })
			})
		}
	  },
  mode: 'history',
  routes: [
    // {
    //   name: 'splash',
    //   path: '/',
    //   component: PageSplash,
    //   meta: {
    //     requireAuth: false,
    //     requireAccount: false,
    //     title: 'Home'
    //   }
    // },
    {
      name: 'login',
      alias: '/',
      path: '/manage',
      component: PageLogin,
      meta: {
        requireAuth: false,
        requireAccount: false,
        title: 'Login'
      }
    },
    {
      name: 'register',
      path: '/register',
      component: () => import('@/pages/PageRegister.vue'),
      meta: {
        requireAuth: false,
        requireAccount: false,
        title: 'Register'
      }
    },
    {
      name: 'recover-password',
      path: '/recover-password',
      component: () => import('@/pages/PageRecoverPassword.vue'),
      meta: {
        requireAuth: false,
        requireAccount: false,
        title: 'Recover Password'
      }
    },
	{
		name: 'media-outliers',
		path: '/media-outliers/:factor(by-subscribers|by-average-views)?/:outlierStatus(ignored|saved)?',
		component: () => import('@/pages/PageMediaOutliers.vue'),
		meta: {
			requireAuth: true,
			requireAccount: true,
			showMenu: 'account',
			title: 'Media Outliers'
		}
	},
	{
		name: 'vision-profiles',
		path: '/vision-profiles',
		component: () => import('@/pages/PageVisionProfiles.vue'),
		meta: {
			requireAuth: true,
			requireAccount: true,
			showMenu: 'account',
			title: 'Vision Profiles'
		}
	},
	{
		name: 'vision-reports',
		path: '/vision-reports',
		component: () => import('@/pages/PageVisionReports.vue'),
		meta: {
			requireAuth: true,
			requireAccount: true,
			showMenu: 'account',
			title: 'Vision Reports'
		}
	},
    {
      name: 'reset-password',
      path: '/reset-password/:email/:token',
      component: () => import('@/pages/PageResetPassword.vue'),
      meta: {
        requireAuth: false,
        requireAccount: false,
        disableRouteBasedOnAuthStatus: true,
        title: 'Reset Password'
      }
    },
    {
      name: 'accept-collaboration',
      path: '/accept/:collaborationId',
      component: () => import('@/pages/PageAcceptCollaboration.vue'),
      meta: {
        requireAuth: false,
        requireAccount: false
      }
    },
    {
      name: 'home',
      path: '/home',
      component: () => import('@/pages/PageHome.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        tour: {
          name: 'app',
          target: '#tour-watch-tour'
        }
      }
    },
	// Route for IDs (24 alphanumeric characters)
	{
		name: 'agreementById',
		path: '/agreement/:agreementId([A-Za-z0-9]{24})',
		component: () => import('@/pages/PageAgreement.vue'),
		meta: {
		requireAuth: false,
		requireAccount: false,
		showMenu: false,
		hidePrintHeader: true,
		title: 'Agreement'
		}
	},
	// Route for slugs (1 to 23 characters)
	{
		name: 'agreementBySlug',
		path: '/agreement/:slug',
		component: () => import('@/pages/PageAgreement.vue'),
		meta: {
		requireAuth: false,
		requireAccount: false,
		showMenu: false,
		hidePrintHeader: true,
		title: 'Agreement'
		}
	},
  
    {
      name: 'w9',
      path: '/collab/:collabId/w9',
      component: () => import('@/pages/PageTaxDocument.vue'),
      meta: {
        requireAuth: false,
        requireAccount: false,
        showMenu: false
      }
    },
    {
      name: 'app-config',
      path: '/app-config',
      component: () => import('@/pages/PageAppConfig.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account'
      }
    },
    {
      name: 'app-queue',
      path: '/app-queue',
      component: () => import('@/pages/PageAppQueue.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account'
      }
    },
    {
      name: 'errors',
      path: '/errors',
      component: () => import('@/pages/PageErrors.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account'
      }
    },
    {
      name: 'engagement',
      path: '/engagement',
      component: () => import('@/pages/PageEngagement.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account'
      }
    },
    {
      name: 'notifications',
      path: '/notifications',
      component: () => import('@/pages/PageNotifications.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account'
      }
    },
    {
      name: 'subscribed',
      path: '/subscribed',
      component: () => import('@/pages/PageEngagement.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account'
      }
    },
    {
      name: 'team',
      path: '/team',
      component: () => import('@/pages/PageTeam.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Team'
      }
    },
    {
      name: 'billing',
      path: '/billing',
      component: () => import('@/pages/PageBilling.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Billing'
      }
    },
    {
      name: 'user',
      path: '/me',
      component: () => import('@/pages/PageUserSettings.vue'),
      meta: {
        requireAuth: true,
        requireAccount: false,
        showMenu: 'account',
        title: 'User settings'
      }
    },
    {
      name: 'user-settings',
      path: '/users/:userId',
      component: () => import('@/pages/PageUserSettings.vue'),
      meta: {
        requireAuth: true,
        requireAccount: false,
        showMenu: 'account',
        title: 'User settings'
      }
    },
    {
      name: 'users',
      path: '/users',
      component: () => import('@/pages/PageUsers.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Users'
      }
    },
    {
      name: 'account-settings',
      path: '/account/settings',
      component: () => import('@/pages/PageAccountSettings.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Account Settings'
      }
    },
    {
      name: 'topics',
      path: '/topics',
      component: () => import('@/pages/PageTopics.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Search Creators',
        tour: {
          name: 'app',
          target: '#search-container'
        }
      }
    },
    {
      name: 'media',
      path: '/media',
      component: () => import('@/pages/PageMedia.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Media'
      }
    },
    {
      name: 'social-entities',
      path: '/social-entities',
      component: () => import('@/pages/PageSocialEntities.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Social Channels'
      }
    },
    {
      name: 'creator',
      path: '/profile/:creatorId/:section?',
      alias: '/creators/:creatorId/:section?',
      component: () => import('@/pages/PageCreator.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: route => store.getters['account/byId'](route.params.creatorId).name
      }
    },
    {
      name: 'collab',
      path: '/collab/:collabId/:section?',
      component: () => import('@/pages/PageCollab.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
		hidePrintHeader: true,
        title: route => {
					const collab = store.getters['collab/byId'](route.params.collabId);
					const creator = store.getters['account/byId'](collab.creator);
					const project = store.getters['project/byId'](collab.project);
					return `${creator.name} | ${project.name}`
				}
      }
    },
    {
      name: 'collabs',
      path: '/collabs',
      component: () => import('@/pages/PageProject.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Collabs'
      }
    },
    {
      name: 'creators-to-review',
      path: '/creators-to-review',
      component: () => import('@/pages/PageCreators.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Creators to Review'
      }
    },
    {
      name: 'payments-and-taxes',
      path: '/payments-and-taxes/:section?/:a11?',
      component: () => import('@/pages/PageTaxDocuments.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Tax Documents'
      }
    },
    {
      name: 'creator-settings',
      path: '/creators/:creatorId/settings',
      component: () => import('@/pages/PageCreatorSettings.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Creators'
      }
    },
    {
      name: 'brand-settings',
      path: '/brands/:brandId',
      component: () => import('@/pages/PageBrandSettings.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Brands'
      }
    },
    {
      name: 'projects',
      path: '/projects',
      component: () => import('@/pages/PageProjects.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Projects'
      }
    },
    {
      name: 'project-settings',
      path: '/projects/:projectId/settings',
      component: () => import('@/pages/PageProjectSettings.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'List Settings'
      }
    },
    {
      name: 'project',
      path: '/projects/:projectId',
      component: () => import('@/pages/PageProject.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
		printable: true,
        title: route => store.getters['project/byId'](route.params.projectId).name
      }
    },
    {
      name: 'project-notes',
      path: '/projects/:projectId/notes',
      component: () => import('@/pages/PageProjectNotes.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Project Notes'
      }
    },
    {
      name: 'project-performance',
      path: '/projects/:projectId/analytics',
      component: () => import('@/pages/PageProjectPerformance.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
		printable: true,
        title: route => store.getters['project/byId'](route.params.projectId).name + ' Analytics'
      }
    },
    {
      name: 'connections',
      path: '/business/connections',
      component: () => import('@/pages/PageConnections.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Services'
      }
    },
    {
      name: 'billing',
      path: '/billing',
      component: () => import('@/pages/PageBilling.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Billing'
      }
    },
    {
      name: 'creators',
      path: '/creators',
      component: () => import('@/pages/PageCreators.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Search Results',
        tour: {
          name: 'app',
          target: '#tour-filter-search-results'
        }
      }
    },
    {
      name: 'payments',
      path: '/payments/:a11?',
      component: () => import('@/pages/PagePayments.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Payment History'
      }
    },
    {
      name: 'brands',
      path: '/brands',
      component: () => import('@/pages/PageBrands.vue'),
      meta: {
        requireAuth: true,
        requireAccount: true,
        showMenu: 'account',
        title: 'Brands'
      }
    },
    {
      name: 'select-plan',
      path: '/select-plan',
      component: () => import('@/pages/PageSelectPlan.vue'),
      beforeEnter (to, from, next) {
        store.dispatch('account/hasAny').then(hasAny => {
            if(hasAny) {
                return next({name: 'home'});
            }
            next();
        }).catch(() => {
            next();
        });
      },
      meta: {
        requireAuth: true,
        requireAccount: false
      }
    }
  ]
})



router.beforeEach(async (to, from, next) => {
  nprogress.start()
  try{
    store.commit('app/SET_FROM_ROUTE', from);
    if (to.meta.requireAuth) {
      if(to.query && to.query.u) {
        // this is a share link with an auth code
        await store.dispatch('user/loginWithAuthCode', to.query.u);
        return next({name:'login', query: {redirect: to.path}});
      }
      try{
		await store.dispatch('user/authenticate');
        if (to.meta.requireAccount && !await store.dispatch('account/hasAny')) {
          console.log(to.path, 'Route access denied. Account required.')
          if(to.name !== 'select-plan') {
            console.log('redirecting to select-plan');
            return next({name: 'select-plan'});
          }
        }
        console.log('not redirecting');
        return next();
      }catch (e){
        console.log(to.path, 'Route access denied. Authentication required.')
        next({ name: 'login', query: { redirect: to.path } })
      }
    } else {
      return next()
    }
  } catch(e){
    console.error('router.beforeEach error', e);
    next('/');
  }
})


router.afterEach((to) => {
  nprogress.done(true)
})

export default router
