<template>
  <div class="page">
	<div class="print-header" v-if="$root.isPrintMode && !($route.meta.hidePrintHeader || false)">
        <img
          src="/img/creator-velocity-icon.png"
		  class="cv-icon"
          alt="Creator Velocity"
        >
		<span class="doc-title">
			{{ printTitle }}
		</span>
		<span class="powered-by">
			Powered by
		</span>
		<TheLogo color="white" />
	</div>
    <slot name="header" />
    <div
      v-if="$slots.scroll"
      class="vue-pull-to-wrapper"
    >
      <template
        slot="top-block"
      >
        <div
          ref="pullWrapper"
          :class="classes"
        >
          <ProgressSpinner v-if="pull.showSpinner" />
          <IconSvg
            v-if="pull.showIcon"
            ref="pullIcon"
            :name="pull.showIcon"
          />
          {{ pull.text }}
        </div>
      </template>
      <slot name="scroll" />
    </div>
    <slot />

	<table class="print-footer" v-if="$root.isPrintMode && !($route.meta.hidePrintHeader || false)" width="100%">
		<tr><td>&nbsp;</td></tr>
		<tr>
			<td>
				<div style="display: flex; gap: 0.5em; align-items: center; justify-content: flex-end">
					<span class="powered-by">
						Powered by
					</span>
					<TheLogo />
				</div>
			</td>
		</tr>
	</table>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
// import PullTo from 'vue-pull-to'

import IconSvg from '@/components/atoms/IconSvg.vue'
import ProgressSpinner from '@/components/atoms/ProgressSpinner.vue'
import TheLogo from "@/components/atoms/TheLogo.vue";

export default Vue.extend({
  components: {
    // PullTo,
    IconSvg,
    ProgressSpinner,
	TheLogo
  },
  props: {
    load: {
      type: Function,
      default: async callback => {
        setTimeout(() => {
          callback('loaded')
        }, 2000)
      }
    },
	printTitle: {
		type: String,
		default: ''
	}
  },
  data () {
    return {
      triggerAudio: null,
      refreshAudio: null,
      pulls: 0,
      pull: {
        state: null,
        showSpinner: false,
        showIcon: false,
        text: ''
      },
      verbs: [
        'Finding more likes',
        'Defrosting',
        'Dont you just love data?',
        'Mmm.. bytes.',
        'Is it there yet?',
        'Starting time machine',
        'Checking again',
        'Here it comes',
        'This is fun isn\'t it?'
      ]
    }
  },
  computed: {
    classes () {
      return {
        'top-load-container': true,
        active: this.pull.state !== 'pull'
      }
    }
  },
  methods: {
    refresh (loaded) {
      this.load().then(() => {
        loaded('done')
      }).catch(() => {
        loaded('fail')
      })
    },
    onPull (pull) {
      if (this.$refs.pullIcon) {
        const startAngle = 90
        const angle = Math.min(270, Math.max(90, startAngle + ((pull - 20) * 4.8)))
        this.$refs.pullIcon.$el.style.transform = `rotate(${angle}deg)`
      }
    },
    stateChange (state) {
      console.log(state)
      if (state === 'pull') {
        this.pull = {
          state,
          showSpinner: false,
          showIcon: 'chevron-arrow',
          text: 'Pull down to refresh'
        }
      } else if (state === 'trigger') {
        // AudioController.play('Notification')
        this.pull = {
          state,
          showSpinner: false,
          showIcon: false,
          text: '🖐 Release!'
        }
      } else if (state === 'loading') {
        let text
        if (this.pulls === 5) {
          text = 'Okay that\'s enough'
        } else if (this.pulls === 7) {
          text = 'Again, really?'
        } else if (this.pulls === 9) {
          text = 'Come on. Last one.'
        } else if (this.pulls === 10) {
          text = 'I hope you had as much fun as us.'
        } else if (this.pulls === 11) {
          text = 'We literally ran out of emojis.'
        } else if (this.pulls === 14) {
          text = 'Just kidding we have more!'
        } else {
          text = this.verbs[Math.floor((Math.random() * this.verbs.length))] + '...'
        }
        this.pull = {
          state,
          showSpinner: true,
          showIcon: false,
          text: text
        }
      } else if (state === 'loaded-done') {
        setTimeout(() => {
          this.pull = {
            state,
            showSpinner: false,
            showIcon: 'check',
            text: null
          }
          this.pulls++
        }, 200)
      }
    }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/mixins';
  @import '../../less/page-sections';
  @import '../../less/variables';

  .center{
    text-align: center;
  }

  .page{
    flex-grow: 1;
    min-width: 100vw;
    box-sizing: border-box;
    padding-bottom: @mobile-footer-height;
    & > h1, & > h2, & > h3, & > h4, & > p {
      margin-left: @mobile-horizontal-padding;
      margin-right: @mobile-horizontal-padding;
    }
  }

  /deep/ .narrow{
    box-sizing: border-box;
    max-width: var(--column-width-readable);
  }

  /deep/ .scroll-container{
    padding: 20px;
  }

  .mobile-card, /deep/ .mobile-card{
  }

  .page.full-screen{
    background: @grayExtraLight;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  

  .print .page.full-screen{
	background: white;
	flex-direction: column;
	padding-top: 0;
  }

  .toolbar{
    font-size: 90%;
  }

  /deep/.action-block-top{
    flex-shrink: 0;
  }

  .vue-pull-to-wrapper{
    // max-height: calc(100vh - @mobile-header-height - @mobile-footer-height);
    padding-left: 1em;
    padding-right: 1em;
	padding-top: 1em;
  }

  .top-load-container {
    line-height: 50px;
    text-align: center;
    font-size: 1rem;
    color: @grayLightMedium;
    &.active {
      color: @grayMedium;
    }
    .chevron-arrow {
      transform: rotate(90deg);
    }
    .check {
      color: @green;
      font-size: 150%;
    }
    .icon {
      animation: fade 200ms forwards;
    }
  }

  @media (min-width: @firstbreakpoint){
    .mobile-card, /deep/ .mobile-card {
      padding: 0;
    }
    .page{
      min-width: 320px;
      &.full-screen{
        padding-top: 100px;
      }
    }
    /deep/ .vue-pull-to-wrapper{
      max-height: revert;
      height: auto !important;
      > .scroll-container{
        overflow: visible !important;
        padding:0 1.5rem !important;
      }
    }
    .top-load-container{
      display: none;
    }
    h1, h2, h3, h4, p {
      margin-left: 0;
      margin-right: 0;
    }
  }






  .print-header{
	background: black;
	margin-bottom: 1em;
	display: flex;
	align-items: center;
	color: #999;
	padding: 0.5em 1.25em 0.5em 0.5em;
	gap: .5em;
	border-radius: 100px;
	display: flex;
	position: absolute;
	top:0;
	left: 0;
	right: 0;
	pointer-events: none;
	.cv-icon{
		width: .25in;
		height: .25in;
	}
	.powered-by{
		margin-left: auto;
		font-size: 80%;
		font-style: italic;
	}
	.doc-title{
		color: white;
		font-size: .22in;
		line-height: 1em;
		text-transform: uppercase;
		font-weight: 700;
		margin-top: -0.05in;
	}
	.the-logo{
		width: .7in;
		margin: 0;
		height: .2in;
	}
  }


  .print-footer{
	color: #999;
    page-break-inside: avoid !important;
	.powered-by{
		font-style: italic;
	}
	.the-logo{
		width: 1.5in;
		margin: 0;
	}
  }
</style>
