<template>
  <div class="app">
	<div id="print-pdf-overlay" data-html2canvas-ignore="true">
		<p>
			<ProgressSpinner />
			Generating PDF
		</p>
		<p class="size-xs grayMedium">
			Please wait...
		</p>
	</div>
    <Sidebar
	  v-if="$store.getters['user/isAuthenticated']"
      :show-install-prompt="!!pwaInstallPrompt"
      @click.native="showMobileMenu=false"
    />
    <div
      v-if="showRouterView"
      class="full-width app-content"
    >
      <div
        v-if="showHeader && $mq !== 'mobile'"
        class="app-header-background"
		data-html2canvas-ignore="true"
      />
	  <div v-if="$root.isMobile" style="height: 50px; min-height:50px; z-index:2; background: black; width: 100vw; padding: 0.5em; box-sizing: border-box; display: flex; align-items: center;">
		<router-link :to="{name: 'home'}" style="display: flex; align-items: center;">
			<TheLogo color="white" style="width:100px;"/>
		</router-link>
	  </div>
      <header
        v-if="showHeader"
        class="app-header-menu"
		data-html2canvas-ignore="true"
      >
        <div v-if="showUpgradeButton && $mq !== 'mobile'">
          <Button
            v-tooltip="`Upgrade to Pro`"
            label="Upgrade"
            class="upgrade primary"
            icon="heart"
            @click="$router.push({name: 'select-plan'})"
          />
        </div>
        <div
          v-if="false"
          class="search-container"
        >
          <IconSvg
            name="magnifying-glass"
            setup
          />
          <input
            type="text"
            class="search"
            placeholder="Search clients, hashtags, etc"
          >
        </div>
        <a
          v-if="$route.meta.tour && !$root.isMobile"
          id="tour-watch-tour"
          href="#"
          style="font-weight: 500;"
          @click="$store.commit('app/SET_TOUR_STATUS', {tour: 'app', status: 'incomplete'})"
        >
          <IconSvg name="info" />
          Watch Tour
        </a>
        <div v-if="$route && $route.meta && $route.meta.printable && !$root.isMobile">
          <a
            href="#"
            style="font-weight: 500;"
            @click="$router.push({query: { print: true}})"
          >
            <IconSvg
              name="download"
              style=" margin-top: -0.1em;"
            />
			Export PDF
          </a>
        </div>
        <div v-if="$route && $route.name && $route.name.includes('project')">
          <a
            href="#"
            style="font-weight: 500;"
            @click="showShareLinkModal=true"
          >
            <IconSvg
              name="link"
              style="font-size: 0.8em; margin-top: -0.1em;"
            />
            Share this page
          </a>
        </div>

        <NavigationUser
          v-if="!$root.isMobile"
          :show-name="false"
		  :dropdown="true"
        />
        <div
          v-if="$mq !== 'mobile'"
          :class="{'notifications-button': true, ringing: $store.getters['notification/unreadCount'] > 0}"
          @click="showNotifications=!showNotifications"
        >
          <IconSvg
            name="bell"
            setup
          />
          <span
            v-if="$store.getters['notification/unreadCount'] > 0"
            class="number-badge"
          >
            {{ $store.getters['notification/unreadCount'] }}
          </span>
        </div>
      </header>
      <router-view />
      <FormMediaDragDrop
        @success="onUploadMediaSuccess"
      />
    </div>
    <NotificationList
      v-if="showNotifications"
      id="notifications"
      :notifications="notifications"
      :show-header="true"
      @close="showNotifications=false"
    />
    <NotificationPopup />
    <transition name="loading-screen">
      <div
        v-if="showLoadingScreen"
        class="loading-screen-container"
      >
        <div class="loading-screen">
          <h1><ProgressSpinner /></h1>
          <h2>{{ loadingMessage }}...</h2>
        </div>
      </div>
    </transition>
    <transition name="appStatus">
      <div
        v-if="$store.state.app.statusMessage"
        id="app-status"
        class="save-status"
      >
        <IconSvg name="check" />
        {{ $store.state.app.statusMessage }}
      </div>
    </transition>
    <SocketConnectionError />
    <BaseModal
      v-if="showShareLinkModal"
      title="Share this page"
      @close="showShareLinkModal=false"
    >
      <FormShareLink />
    </BaseModal>
    <BaseModal
      v-if="$store.state.app.showProfiler"
      @close="$store.commit('app/SET_SHOW_PROFILER', false)"
    >
      <TableSystemProfile />
    </BaseModal>
    <BaseModal
      v-if="$store.state.app.upgradeModalReason"
      @close="$store.commit('app/SET_UPGRADE_MODAL_REASON', '')"
    >
      <ModalUpgrade />
    </BaseModal>
    <BaseModal
      v-if="showServerRedirect"
      style="z-index:71"
      title="Redirecting to Staging..."
      @close="$store.commit('app/SET_CANCELLED_SERVER_REDIRECT', true)"
    >
      <ServerRedirect
        @cancelRedirect="$store.commit('app/SET_CANCELLED_SERVER_REDIRECT', true)"
      />
    </BaseModal>
    <ModalConfirmWarning
      v-if="$store.state.app.appOutOfSyncWithServer && !$store.state.app.hideAppOutOfSyncError"
      title="Update Available"
      cancel-button-label="Cancel"
      confirm-button-label="Update Now"
      confirm-button-icon="refresh"
      @confirm="$store.dispatch('app/clearLocalCache', {refresh: true})"
      @close="$store.commit('app/SET_HIDE_APP_OUT_OF_SYNC_ERROR', true)"
    >
      <p>
        Please reload the page to get the latest version.
      </p>
      <p class="size-s grayMedium">
        Continuing without updating may lead to performance or stability issues.
      </p>
    </ModalConfirmWarning>
    <ModalConfirmWarning
      v-if="$store.state.app.actionError"
      title="Oops..."
      confirm-button-label="Reload page"
      confirm-button-icon="refresh"
      @confirm="$store.dispatch('app/clearLocalCache', {refresh: true})"
      @close="$store.commit('app/SET_ACTION_ERROR', null)"
    >
	  <p>
		{{ $store.getters['app/actionErrorMessage'] }}
	  </p>
      <p class="size-xs grayMedium">
        Consider refreshing the page to get the latest version or trying again later.
      </p>
	  
    </ModalConfirmWarning>

    <Tour v-if="!$root.isMobile"/>
  </div>
</template>
<script lang="ts">
// import AudioController from './plugins/AudioController'
import nprogress from 'nprogress';
import Vue from 'vue'
import { VuePwaInstallMixin } from 'vue-pwa-install'


import Button from '@/components/atoms/Button.vue'
import HorizontalRule from '@/components/atoms/HorizontalRule.vue'
import IconSvg from '@/components/atoms/IconSvg.vue'
import NavigationUser from '@/components/atoms/NavigationUser.vue'
import ProgressSpinner from '@/components/atoms/ProgressSpinner.vue'
import BaseModal from "@/components/molecules/BaseModal.vue";
import PopoverMenu from '@/components/molecules/PopoverMenu.vue'
import PopoverMenuItem from '@/components/molecules/PopoverMenuItem.vue'
import ServerRedirect from "@/components/molecules/ServerRedirect.vue";
import FormMediaDragDrop from '@/components/organisms/FormMediaDragDrop.vue'
import FormShareLink from "@/components/organisms/FormShareLink.vue";
import ModalConfirmWarning from "@/components/organisms/ModalConfirmWarning.vue";
import ModalUpgrade from "@/components/organisms/ModalUpgrade.vue";
import NotificationList from '@/components/organisms/NotificationList.vue'
import NotificationPopup from '@/components/organisms/NotificationPopup.vue'
import Sidebar from '@/components/organisms/Sidebar.vue'
import SocketConnectionError from '@/components/organisms/SocketConnectionError.vue'
import TableSystemProfile from "@/components/organisms/TableSystemProfile.vue";

import html2pdf from 'html2pdf.js/dist/html2pdf.bundle.js'
import TheLogo from '@/components/atoms/TheLogo.vue';

export default Vue.extend({
  name: 'App',
  components: {
    ModalUpgrade,
    ModalConfirmWarning,
    ServerRedirect,
    TableSystemProfile,
    FormShareLink,
    BaseModal,
    IconSvg,
    Sidebar,
    Button,
    NavigationUser,
    HorizontalRule,
    FormMediaDragDrop,
    NotificationList,
    NotificationPopup,
    ProgressSpinner,
    PopoverMenu,
    PopoverMenuItem,
    SocketConnectionError,
	TheLogo
  },
  mixins: [VuePwaInstallMixin],
  data () {
    return {
      showRouterView: false,
      showMobileMenu: false,
      showNotifications: false,
      showLoadingScreen: false,
      showShareLinkModal: false,
      loadingMessageTimeout: null,
      loadingMessageIndex: 0,
      loadingMessages: [
        'Checking the monitors',
        'Turning it up to 11',
        'Sound checking'
      ],
      pwaInstallPrompt: null,
      saveStatus: null
    }
  },
  computed: {
    isAuthenticated (): boolean {
      return this.$store.getters['user/isAuthenticated']
    },
    showSidebar (): boolean {
      const routeUsesMenu = this.$route.meta.showMenu
      return routeUsesMenu && this.isAuthenticated && (this.$root.isMobile ? this.showMobileMenu : true)
    },
    showHeader (): boolean {
      return this.isAuthenticated
    },
    notifications () {
      return this.$store.getters['notification/all']
    },
    unreadNotificationCount () {
      return this.$store.getters['notification/unreadCount']
    },
    selectedAccount () {
      return this.$store.getters['account/selected']
    },
    hasAnyAccounts () {
      return this.$store.getters['account/brandsForUser'].length > 0
    },
    showUpgradeButton () {
      return this.selectedAccount.isSubscribed === false
    },
    routeIsPublic () {
      return this.$route.meta.requireAuth === false
    },
    routeRequiresAccount(){
      console.log(this.$route);
      return this.$route.meta.requireAccount === true;
    },
    routeRequiresAuth () {
      return this.$route.meta.requireAuth === true
    },
    loadingMessage () {
      return this.loadingMessages[this.loadingMessageIndex]
    },
    hasTopicsSelected(){
      try {
        return this.$store.state.account.creatorFilterSettings.contentTopics.length > 0
      } catch(e){
        return false;
      }
    },
    showServerRedirect(){
      const isAuthed = this.$store.getters['user/isAuthenticated'];
      const isAdmin = this.$store.getters['user/isAdmin'];
      const isProd = window.location.host === 'app.creatorvelocity.com';
      const cancelled = this.$store.state.app.cancelledServerRedirect;
      return isAuthed && isAdmin && isProd && !cancelled;
    },
	isPrintMode(){
		return this.$root.isPrintMode
	}
  },
  watch: {
    isAuthenticated: async function (isAuthenticated) {
      console.log('user authenticated', isAuthenticated, this.routeRequiresAccount);
      nprogress.start({speed: 5000});
      this.showLoadingScreen = true;
      await this.loadRequiredVuexDependencies();
      await this.routeBasedOnAuthStatus().catch(e => {
				console.error(e)
			});

      if (isAuthenticated) {
        // I don't think we're using this code anymore
        // wait for the state to fully restore
        // await new Promise(resolve => {
        //   // Check every 100ms. Adjust as necessary.
        //   const checkInterval = setInterval(() => {
        //     if (this.$store.state.app.stateRestored) {
        //       console.log('state restored');
        //       clearInterval(checkInterval);
        //       resolve();
        //     }
        //   }, 250);
        //   setTimeout(() => {
        //     // check if it's resolved yet
        //     if (!this.$store.state.app.stateRestored) {
        //       clearInterval(checkInterval);
        //       resolve();
        //       console.warn('State restore timed out.');
        //     }
        //   }, 10000);
        // })

        console.log('loading extra dependencies');
        await Promise.all([
          // Wait AT LEAST 1 second before continuing
          new Promise(resolve => setTimeout(resolve, 1000)),
          // Preloading these to improve user experience
          this.loadExtraVuexDependencies()
        ]).catch(e => {
          alert('Error 112: Could not load database.')
          console.error(e)
        })
		

		if(this.$store.getters['user/isAdmin']){
			this.$store.dispatch('creatorVision/loadMediaOutliers')
		}

      }
	  nprogress.done();
	  this.showLoadingScreen = false;
      console.log('got here');
    },
    $route () {
      // hide mobile menu on navigation
      if (this.showMobileMenu) {
        this.showMobileMenu = false
      }
      this.updateWindowTitle()
	  if(this.$route.query.print === 'true'){
		this.$nextTick(() => {
			// When the view updates, save it as a PDF
			this.savePdf()
		})
	  }	
    },
    unreadNotificationCount () {
      this.updateWindowTitle()
    },
    showLoadingScreen () {
      this.nextLoadingMessage()
    },
	isPrintMode: {
		handler: function(){
			if(this.isPrintMode){
				// add "print" class to body
				document.body.classList.add('print')
			}else{
				// remove "print" class from body
				document.body.classList.remove('print')
			}
		},
		immediate: true
	}
  },
  created () {
    // AudioController.init()
    this.$store.dispatch('user/loadMe')
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', event => {
        const { data } = event
        // event is a MessageEvent object
        if (data.type === 'notification') {
          this.$store.dispatch('notification/display', data.notification)
        }
      })
    }
    this.$on('canInstall', (event) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt:
      event.preventDefault()

      // Stash the event so it can be triggered later:
      this.pwaInstallPrompt = event
    })

    // Handle periodical reloads
    // =========================
    setInterval(() => {
      this.$store.dispatch('app/periodicalReload');
    }, 60 * 1000);

    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        this.$store.dispatch('app/periodicalReload');
      }
    });

  },
  mounted () {
    setTimeout(() => {
      this.showRouterView = true
    }, 550)
  },
  methods: {
    loadRequiredVuexDependencies () {
      return Promise.all([
        this.$store.dispatch('appConfig/load'),
        this.$store.dispatch('account/load')
      ])
    },
    loadExtraVuexDependencies () {
      return Promise.all([
        this.$store.dispatch('collab/load'),
        this.$store.dispatch('notification/load'),
        this.$store.dispatch('service/load'),
        this.$store.dispatch('socialEntity/load'),
        this.$store.dispatch('collaboration/load'),
        this.$store.dispatch('user/load'),
        this.$store.dispatch('project/load'),
        this.$store.dispatch('media/load'),
        this.$store.dispatch('payment/load'),
        this.$store.dispatch('message/load'),
        this.$store.dispatch('note/load'),
        this.$store.dispatch('agreement/load'),
        this.$store.dispatch('taxDocument/load'),
        this.$store.dispatch('agreementDeliverable/load'),
        this.$store.dispatch('agreementPaymentTerm/load'),
        this.$store.dispatch('agreementShipment/load'),
        this.$store.dispatch('address/load'),
        this.$store.dispatch('objectEventSubscription/load')
      ])
    },
    async getRouteBasedOnAuthStatus(){
      if (this.routeIsPublic && this.isAuthenticated) {
        // this means they just logged in from a public page.
        if (await this.$store.dispatch('account/hasAny')) {
          // USER HAS AN ACCOUNT
          // send them to the home page
          // ===========================
          if(this.$route.query.redirect){
            return this.$route.query.redirect
          }
          return {
            name: 'home'
          }
        } else {
          // USER SIGNED IN AND HAS NO ACCOUNT
          // send them to select a plan
          // ===========================
          return {
            name: 'select-plan'
          }
        }
      } else if (this.routeRequiresAuth && !this.isAuthenticated) {
        // USER SIGNED OUT
        // send them to the LOGIN PAGE
        // ===========================
        return {
          name: 'login'
        }
      }
      return false;
    },
    routeBasedOnAuthStatus: async function () {
      const route = await this.getRouteBasedOnAuthStatus();
      if (route && !this.$route.meta.disableRouteBasedOnAuthStatus) {
        console.log('Routing based on auth status', route);
        await this.$router.push(route).catch(e => {
          console.error('could not route based on auth status', e);
        });
      }
      clearTimeout(this.loadingMessageTimeout)
    },
    onUploadMediaSuccess (media) {
      this.$store.commit('media/SET', media)
    },
    updateWindowTitle () {
			let title;
			if(this.$route.meta.title){
				if(typeof this.$route.meta.title === 'function'){
					try{
						title = this.$route.meta.title(this.$route)
					} catch(e){
						console.error('Failed to get page title', e);
					}
				} else if(typeof this.$route.meta.title === 'string') {
					title = this.$route.meta.title
				}
			}
      title = title || 'Creator Velocity'
      if (this.unreadNotificationCount > 0) {
        title = `(${this.unreadNotificationCount}) ${title}`
      }
      document.title = title
    },
    nextLoadingMessage () {
      this.loadingMessageIndex = (this.loadingMessageIndex + 1) % this.loadingMessages.length
      this.loadingMessageTimeout = setTimeout(() => {
        this.nextLoadingMessage()
      }, 1500)
    },
    async savePdf(){
		const element = document.querySelector('.app-content');
		// get the title from the document
		const options = {
			margin: 0.5,
			filename: `${document.title}.pdf`,
			image: { type: 'jpeg', quality: 0.98 },
			pagebreak: {
				mode: ['avoid-all', 'css', 'legacy'],
				avoid: 'tr'
			},
			html2canvas: {
				allowTaint: false,
				useCors: true,
				letterRendering: true,
				scale: 2,
				onclone: function (doc) {
					const svgElements: any[] = doc.getElementsByTagName("svg");
					Array.from(svgElements).forEach((svgElement) => {
						const bBox: any = svgElement.getBBox();
						svgElement.setAttribute("width", bBox.width);
						svgElement.setAttribute("height", bBox.height);
					});
				}
			},
			jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },	
			enableLinks: true,
			style: `
				@page {
					margin: 0;
					html, body{
						font-size: 10px;
					}
				}
			`
		}; 
		console.log('saving pdf')

		const convertImagesToDataURLs = async () => {
			const imgElements = document.getElementsByTagName('img');

			for (const img of imgElements) {
				console.log('Got an image', img.src)
				const now = String( Date.now())
				img.crossOrigin = 'Anonymous';
				if(!img.src.includes('s3-proxy')){
					img.src = `/api/utilities/s3-proxy?url=${img.src}`
					console.log('converted image', img.src)
				}
			}
		};

		// Call the function to convert images to data URLs
		await convertImagesToDataURLs();

		await html2pdf().from(element).set(options).save().then(async () => {
			console.log('saved pdf')
		}).catch(async e => {
			console.error('Failed to save PDF', e);
		})

		if(this.$route.query.redirect){
			console.log('going to redirect', this.$route.query.redirect)
			await this.$router.replace(this.$route.query.redirect)
		}else{
			console.log('going back')
			await this.$router.back();
			await this.$router.back();
		}
	} 
  }
})
</script>
<style lang="less">
  @import './less/nprogress';
  @import './less/variables';
  @import './less/typography';
  @import './less/mixins';
  @import './less/transitions';
  @import './less/tooltips';

  *{
    line-height: 1.75em;
  }

  html, body{
    background: @grayExtraLight;
    color: @grayDark;
    font-weight: 500;
  }

  .app{
    display: flex;
    align-items: stretch;
    min-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    max-height: 100vh;
  }


  .print .app{
		max-width: 8.5in !important
	}

	#print-pdf-overlay{
		position: fixed;
		display: none;
		position: fixed;
		z-index: 9999;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: white;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-size: 150%;
	}
	.print #print-pdf-overlay{
		display: flex;
	}

	.dont-break{
		page-break-inside: avoid;
		break-inside: avoid;
	}
	.page-break-after{
		page-break-after: always;
	}
	.page-break-before{
		page-break-before: always;
	}
  .app-header-background{
    height: 122px;
    padding: 0.75em 1.5em;
    box-sizing: border-box;
    background: #fff;
    user-select: none;
    -webkit-app-region: drag;
    box-shadow: 0 5px 5px fade(@black, 5%);
    margin-bottom: -122px;
    flex-shrink: 0;
  }

  .app-header-menu{
    display: none;
	position: relative;
    right: 1.5em;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    gap: 1em;
	font-size: 0.8em;
    pointer-events: none;
	top: 0.5em;
	height: 66px;
	margin-bottom: -66px;
    & > * {
      pointer-events: all;
    }
  }

  #notifications {
    width: 325px;
  }

  .button.upgrade{
    border-radius: 2em;
    display: none;
  }

  .search-container{
    margin-right: 2em;
    text-align: left;
    position: relative;
    width: 250px;
    .icon-svg{
      position: absolute;
      top: 0.7em;
      left: 1em;
      opacity: 0.5;
      color: @grayLightMedium;
    }
    input{
      width: 100%;
      max-width: 600px;
      box-sizing: border-box;
      font-size: 1em;
      border: none;
      padding: 0.5em 1em 0.5em 2.5em;
      font-family: inherit;
      background: @grayLight;
      border-radius: 1.5em;
      outline: none;
      &::placeholder{
        color: @grayLightMedium;
        font-size: 90%;
      }
    }
  }

  .full-width.app-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
	padding-bottom: 3rem;
  }

  @media(min-width: @firstbreakpoint){
	.full-width.app-content{
		padding-bottom: 0;
	}
  }

  .print .full-width.app-content{
	display: block !important;
  }

  .dropdown{
    display: none;
    opacity: 0;
    zoom: 0.9;
    position: absolute;
    right: 0;
    margin-top: -1.5rem;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    padding: 1em;
    min-width: 200px;
    .shadowbox();

    transition: all 0.5s;
    strong {
      font-weight: 500;
      font-size: 120%;
    }
    a{

      font-weight: 500;
    }
    .horizontal-rule{
      margin: 0.5em -1em;
      border-radius: 0;
      height: 1px;
    }
    &::after{
      content: '';
      display: inline-block;
      position: absolute;
      height: 20px;
      width: 20px;
      transform: rotate(45deg);
      border-radius: 2px;
      background: white;
      top: 0;
      margin-top: -7px;
      right: 10px;
    }
  }
  
  .user-container:hover, .user-container:active{
    .dropdown {
      top: 4.5em;
      display: block;
      opacity: 1;
      zoom: 1;
      z-index: 70;
    }
  }

  .save-status{
    position: fixed;
    z-index: 2;
    bottom:0;
    left: 0;
    padding: 0.5em 1em;
    background: @green;
    color: @black;
    font-weight: bold;
    border-top-right-radius: 0.25em;
  }


  .highlight{
    display: inline-block;
    background: @purpleHighlight;
    padding: 0.25em 0.75em;
    border-radius: 10px;
    white-space: nowrap;
  }

  .lowlight{
    display: inline-block;
    padding: 0.25em 0.75em;
    border-radius: 15px;
    background: fade(@black, 10%);
    white-space: nowrap;
  }

  .tag{
    display: inline-block;
    white-space: nowrap;
  }

  span.tag{
    background: rgba(30, 30, 200, 6%);
    padding: 0.25em 0.8em;
    border-radius: 1.5em;
    margin-right: 0.25em;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    vertical-align: middle;
    font-weight: 500;
    &.red{
      background: lighten(@red, 5%);
      color: @white;
    }
    &.green{
      background: lighten(@green, 5%);
    }
    &.yellow{
      background: @yellow;
      color: darken(@yellow, 35%);
    }
    &.purple{
      background: lighten(@purpleVibrant, 5%);
      color: @white;
    }
  }

  .notifications-button{
    background: lighten(@grayLightMedium, 15%);
    height: 35px;
    width: 35px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    position: relative;
    &:hover, &:active{
      background: lighten(@grayLightMedium, 10%);
    }
    &.ringing .icon{
      transform-origin: 50% 0;
      animation: ring 4s .7s ease-in-out infinite
    }
  }

  .number-badge{
    background: @red;
    color: #fff;
    padding: 0 0.25em;
    font-weight: 500;
    position: absolute;
    line-height: 20px;
    min-width: 20px;
    font-size: 12px;
    border-radius: 10px;
    top: -2px;
    right: -2px;
    box-sizing: border-box;
  }

  .loading-screen-container {
    background: @grayLight;
    z-index:70;
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1.1);
    .loading-screen {
      text-align: center;
      animation: zoom-fade 1s forwards;
    }
  }

  .loading-screen-enter-active {
    transition: all 500ms ease-out;
    transform: scale(1.1);
  }

  .loading-screen-leave-active {
    transition: all 200ms ease-in;
  }

  .loading-screen-enter, .loading-screen-leave-to
  /* .loading-screen-leave-active below version 2.1.8 */ {
    transform: scale(1);
    opacity: 0;
  }

  @media(min-width: @firstbreakpoint){
	  .app-header-menu{
		display: flex;
	  }
  }
  

  @keyframes ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }

    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
  }
</style>
