<template>
  <component
    :is="to ? 'router-link' : 'div'"
    v-tooltip.left="{
      content: title.length > 15 ? title: null,
      boundariesElement: $root.body
    }"
    class="nav-menu-item"
    :to="to"
    :class="{'navigation-menu-link': true, selected, expanded }"
    @click.native="handleClick"
  >
    <IconSvg
      v-if="icon && !image"
      :name="icon"
      :class="iconClass"
    />
    <img
      v-if="image"
      :src="image"
    >
    <span
      class="link-label"
    >
      {{ title }}
    </span>
	<slot />
  </component>
</template>
<script lang="ts">
import Vue from 'vue'

import IconSvg from '@/components/atoms/IconSvg.vue'
export default Vue.extend({
  components: {
    IconSvg
  },
  props: {
    to: {
      type: Object,
      required: false
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: [String, Object, null],
      default: ''
    }
  },
  inject: ['expanded'],
  methods: {
    handleClick(e){
      if(!this.to){
        e.preventDefault();
      }
      this.$emit('click', e)
    }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/variables';

  .nav-menu-item{
    font-weight: 500;
    color: @grayLight;
    padding: .75rem 1.5em;
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    vertical-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75em;
    border-radius: 3px;
	&.expanded{
		.expanded();
	}
  }

  .expanded .nav-menu-item{
	.expanded()
  }

  .nav-menu-item {
    &:hover {
      color: @white;
      text-decoration: none;
      background: @grayDark;
    }

    &.router-link-active {
      color: @green;
      background: @grayDark;

      .icon-svg {
        opacity: 1;
      }
    }
  }

  .icon-svg{
    height: 1.5em;
    width: 1.5em;
    flex-shrink: 0;
  }

  img{
    height: 1.5em;
    width: 1.5em;
    flex-shrink: 0;
  }

  img{
    border-radius: 50%;
  }
  
  .link-label{
    display: none;
  }

  /deep/ .tag{
    background: fade(@white, 10%);
    color: @grayMedium;
    border-radius: 1em;
    padding: 0.2em 0.7em;
    font-size: 0.7em;
    text-transform: uppercase;
    margin-left: 0.5em;
  }

  .expanded(){
	justify-content: flex-start;
	.link-tip {
		display: none !important;
	}
      .link-label{
        display: inline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
  }

  /deep/ .badge{
	position: absolute;
	top: 1em;
	right: 1em;
	background: @red;
	color: @white;
	border-radius: 1em;
	display: inline-flex;
	line-height: 0.5em;
	min-width: 1.75em;
	justify-content: center;
	align-items: center;
	font-size: 0.8em;
	border:2px solid black;
	box-sizing: border-box;
	padding: 0.5em 0.25em;
  }
</style>
