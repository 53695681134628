<template>
  <toggle-button
    :value="value"
    :color="color"
	:switch-colors="switchColors"
    @change="$emit('change', $event.value)"
    :height="height || selectedSize.height"
    :width="width || selectedSize.width"
    :font-size="fontSize"
    :labels="labels"
    :css-colors="cssColors"
    sync
  />
</template>
<script lang="ts">
import BaseInput from '@/components/atoms/BaseInput.vue'
import Vue from 'vue'
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton);

export default Vue.extend({
  extends: BaseInput,
  props: {
    size: {
      type: String,
      default: 'small'
    },
    color: {
      type: [Object, String],
      default: () => ({ checked: '#4f4fff', unchecked: '#111111' })
    },
	switchColors: {
	  type: Object,
	  default: () => null
	},
    labels: {
      type: Object,
      default: () => ({ checked: 'On', unchecked: 'Off' })
    },
    height: {
      type: Number
    },
    width: {
      type: Number
    },
    fontSize: {
      type: Number,
      default: 12
    },
    cssColors: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      sizes: {
        small: {
          height: 18,
          width: 40
        },
        medium: {
          height: 22,
          width: 40
        },
        large: {
          height: 24,
          width: 45
        },
        xl: {
          height: 30,
          width: 50
        }
      }
    }
  },
  computed: {
    selectedSize(){
      if(this.sizes[this.size]){
        return this.sizes[this.size]
      }
      return this.sizes['medium']
    }
  }
})

</script>
<style lang="less" scoped>
@import '../../less/variables';
.vue-js-switch {
  width: auto;
  display: inline-block !important;
  padding:0 !important;
  border-radius: 2em;
  background: @grayMedium;
  &.toggled{
    background: @green;
	/deep/ .v-switch-label{
		color: black;
		z-index: 0;
	}
  }

  .v-switch-input {
    background: blue;
  }

  .v-switch-core {
    .v-switch-button {
    }
  }

  &.disabled {

  }
}
</style>