<template>
  <div
    :class="{'modal-container': true, fullscreen: fullscreen, 'as-page': !fullscreen}"
    tabindex="0"
    @keydown.esc="close"
  >
    <div
      class="scroll"
      @click="closeIfDesktop"

      @mousemove="onMouseMove"
    >
      <PageHeader class="mobile-header">
        <a @click="$emit(showBackButton ? 'back' : 'close')" class="button-close-modal">
          <IconSvg name="x" />
          {{ showBackButton ? 'Back' : 'Close' }}
        </a>
        <h1 v-html="title" />
      </PageHeader>
      <div
        class="modal"
        @click.stop
      >
        <div
          ref="content"
          class="modal-content"
        >
          <div class="desktop-header">
            <Button
              v-if="showBackButton"
              class="circle back"
              icon="chevron"
              @click="$emit('back')"
            />
            <div class="modal-title">
              <h2
                v-if="title"
                v-html="title"
              />
              <h3
                v-if="subtitle"
                v-html="subtitle"
              />
            </div>
            <Button
              class="circle"
              icon="x"
              @click="close"
            />
          </div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import Button from '@/components/atoms/Button.vue'
import IconSvg from '@/components/atoms/IconSvg.vue'
import PageHeader from '@/components/molecules/PageHeader.vue'

export default Vue.extend({
  components: {
    IconSvg,
    Button,
    PageHeader
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      isDragging: false
    }
  },
  mounted () {
    this.$el.focus()
  },
  methods: {
    closeIfDesktop () {
      if (!this.isDragging && window.innerWidth >= 600) {
        this.close()
      }
    },
    close () {
      this.$emit('close')
    },
    onMouseMove(event){
       if(event.buttons === 1){
         this.isDragging = true
       } else {
         setTimeout(() => {
           this.isDragging = false
         }, 100)
       }
    }
  }
})
</script>
<style lang="less" scoped>
  @import '../../less/mixins.less';
  @import '../../less/variables';

  .modal-container{
    position: fixed;
    z-index: 20;
    inset: 0;
    display: flex;
    justify-content: center;
    background: @grayLight;
    animation: 200ms fade-in;
    cursor: default;
    font-size: 1rem;
	text-align: left;
  }

  .scroll{
    margin-top: @mobile-header-height;
    max-height: calc(100vh - @mobile-header-height - @mobile-footer-height);
    overflow: auto;
    width: 100%;
	padding-bottom: 2em;
	padding-top: 1em;
	box-sizing: border-box;
  }

  .mobile-header{
    box-shadow: none;
    border-bottom:1px solid @grayLightHover;
    background: @grayLight;
  }

  .modal-title{
    text-align: center;
    padding: 0.5rem 0;
    h2, h3{
      margin: 0;
    }
    h3{
      color: @grayMedium;
    }
  }

  .modal {
    transition: width 250ms;
    width: 100%;
  }

  .modal-content /deep/ .horizontal-rule{
    margin: 1rem -1.5rem;
    border-radius: 0;
    height: 2px;
  }

  .desktop-header {
    display: none;
    grid-template-columns: 30px 1fr 30px;
    align-items: center;
    padding-top: 0.25em;
    border-bottom: 1px solid @grayLight;
    .modal-title{
      grid-column-start: 2;
    }
    button {
      color: @grayMedium;
      box-shadow: none;
      background: @grayLight;
      &:hover, &:active{
        background: @grayLightHover;
      }
    }
    button.back {
      /deep/ .chevron{
        transform: rotate(180deg);
      }
    }
  }

  .as-page{
    .modal{
      .shadowbox();

      box-sizing: border-box;
      margin: 0 auto;
      min-height: 100%;
    }
    .modal-content{
      margin: 0 1.5rem;
      position: relative;
	  padding-bottom: 2.5em;
    }
  }

  .button-close-modal{
	
  }

  /deep/ table {
    box-shadow: none !important;
  }

  /deep/ .page-section{
    box-shadow: none !important;
    padding: 1em 0 !important;
    margin: 0 !important;
  }

  @media(min-width: @firstbreakpoint){
    .modal-container{
      background: fade(@grayLight, 80%);
    }
    .scroll {
      max-height: 100vh;
      margin-top: 0;
	  padding: 0;
    }
    .modal {
      max-width: revert;
      width: 500px;
      border-radius: 12px;
      height: auto;
    }
    .desktop-header {
      display: grid;
    }
    .mobile-header {
      display: none;
    }
    button.close {
      display: inline-block;
    }
    .as-page .scroll, .fullscreen .scroll{
      padding: 3rem 1rem;
    }
    .as-page .modal {
      min-height: revert;
    }
    .fullscreen{
      .modal{
        .shadowbox();
        margin: 0 auto;
        border-radius: 12px;
      }
      .modal-content{
        margin: 1rem 1.5rem;
        position: relative;
      }
    }
  }
</style>
